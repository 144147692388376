<template>
  <v-app>
    <v-main class="px-md-10 py-6 py-md-10 py-lg-16">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  beforeCreate() {
    document.title = this.$route.name;
  },
};
</script>

<style>
.v-main {
  background-attachment: fixed;
  background-image: url(/img/bg-desktop.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 599.9px) {
  .v-main {
    background-image: url(/img/bg-mobile.png);
  }
}
</style>
