<template>
  <div>
    <v-container>
      <div class="d-flex align-center justify-center header-brands">
        <img
          loading="lazy"
          height="180px"
          src="/img/grupo-fasipe.png"
          alt="Grupo Fasipe Educacional"
          class="d-block brand-group"
        />

        <!-- <img
          loading="lazy"
          height="73px"
          src="/img/separator.png"
          class="mx-5 mx-lg-16 separator"
        />

        <img
          loading="lazy"
          height="217px"
          src="/img/20-anos-fasipe.png"
          alt="20 anos Fasipe"
          class="d-block age"
        /> -->
      </div>

      <v-row justify="center">
        <v-col cols="12" sm="10" md="8" lg="4" class="mt-6 pa-5 pt-4">
          <v-form>
            <div
              class="
                title
                grey--text
                text--darken-2 text-center
                font-weight-bold
                mb-3
              "
            >
              ESCOLHA SUA UNIDADE
            </div>

            <v-select
              background-color="white"
              outlined
              hide-details
              v-model="goTo"
              :items="items"
              label="ESCOLHA SUA UNIDADE"
            ></v-select>
          </v-form>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-4 mt-md-6 mt-lg-8" fluid>
      <div
        class="
          title
          grey--text
          text--darken-4 text-h6 text-md-h5 text-center text-uppercase
          font-weight-bold
        "
      >
        Faculdades Fasipe
      </div>

      <div class="mt-10 grid-list-faculties" justify="center">
        <template v-for="(item, i) in faculties">
          <Brands :key="i" :item="item" />
        </template>
      </div>

      <div
        class="
          title
          grey--text
          text--darken-4 text-h6 text-md-h5 text-center text-uppercase
          font-weight-bold
          mt-16
        "
      >
        Colégios San Petrus
      </div>

      <div class="mt-10 grid-list-schools" justify="center">
        <template v-for="(item, i) in schools">
          <Brands :key="i + faculties.length" :item="item" />
        </template>
      </div>
    </v-container>
  </div>
</template>

<script>
import { getTelLinkFromPhone } from "../utils";

const _faculties = [
  {
    title:
      "UNIFASIPE JET (Jurídica, Engenharias/Empreendedorismo, Tecnológica) | Residencial Florença • Sinop - MT",
    img: require("../../assets/units/faculties/fasipe-florenca.png"),
    imgWidth: "300px",
    link: "https://fasipe.com.br/?lc=florenca",
    address: [
      "Avenida Magda de Cássia Pissinati, nº 69",
      "Residencial Florença, Sinop - MT",
    ],
    tel: [
      {
        num: "(66) 3517-1320",
        link: getTelLinkFromPhone("(66) 3517-1320"),
      },
      {
        num: "(66) 9 9657-5964",
        link: getTelLinkFromPhone("(66) 9 9657-5964"),
      },
    ],
    icons: [
      {
        icon: "fab fa-facebook-square",
        link: "https://www.facebook.com/unifasipe",
        color: "#4267B2",
      },
      {
        src: require("../../assets/instagram.png"),
        link: "https://www.instagram.com/unifasipe/",
        color: "#E1306C",
      },
      {
        icon: "fab fa-youtube",
        link: "https://www.youtube.com/@unifasipecentroeducacional8024",
        color: "#FF0000",
      },
    ],
  },
  {
    title:
      "UNIFASIPE FAS (Faculdade Avançada de Saúde) | Aquarela das Artes • Sinop - MT",
    img: require("../../assets/units/faculties/fasipe-aquarela.png"),
    imgWidth: "300px",
    link: "https://fasipe.com.br/?lc=aquarela",
    address: [
      "Rua Graciliano Ramos, s/n, lote 78D",
      "Aquarela das Artes, Sinop - MT",
    ],
    tel: [
      {
        num: "(66) 3517-1320",
        link: getTelLinkFromPhone("(66) 3517-1320"),
      },
      {
        num: "(66) 9 9657-5964",
        link: getTelLinkFromPhone("(66) 9 9657-5964"),
      },
    ],
    icons: [
      {
        icon: "fab fa-facebook-square",
        link: "https://www.facebook.com/unifasipe",
        color: "#4267B2",
      },
      {
        src: require("../../assets/instagram.png"),
        link: "https://www.instagram.com/unifasipe/",
        color: "#E1306C",
      },
      {
        icon: "fab fa-youtube",
        link: "https://www.youtube.com/@unifasipecentroeducacional8024",
        color: "#FF0000",
      },
    ],
  },
  {
    title: "FACULDADE FASIPE CUIABÁ - MT",
    img: require("../../assets/units/faculties/fasipe-cuiaba.png"),
    imgWidth: "250px",
    link: "https://fasipecuiaba.com.br",
    address: [
      "Rua Jornalista Amaro de Figueiredo Falcão, nº 133",
      "Cpa I, Cuiabá - MT",
    ],
    tel: [
      {
        num: "(65) 3548-3900",
        link: getTelLinkFromPhone("(65) 3548-3900"),
      },
      {
        num: "(65) 9 8464-0233",
        link: getTelLinkFromPhone("(65) 9 8464-0233"),
      },
    ],
    icons: [
      {
        icon: "fab fa-facebook-square",
        link: "https://www.facebook.com/fasipecpa",
        color: "#4267B2",
      },
      {
        src: require("../../assets/instagram.png"),
        link: "https://www.instagram.com/fasipecuiaba/",
        color: "#E1306C",
      },
      {
        icon: "fab fa-youtube",
        link: "https://www.youtube.com/fasipecuiaba",
        color: "#FF0000",
      },
    ],
  },
  {
    title: "FACULDADE FASIPE RONDONÓPOLIS - MT",
    img: require("../../assets/units/faculties/fasipe-rondonopolis.png"),
    imgWidth: "250px",
    link: "https://fasiperondonopolis.com.br",
    address: [
      "Avenida Jacarandás, nº 1224",
      "Parque Sagrada Família, Rondonópolis - MT",
    ],
    tel: [
      {
        num: "(66) 3302-0099",
        link: getTelLinkFromPhone("(66) 3302-0099"),
      },
      {
        num: "(66) 9 9725-6560",
        link: getTelLinkFromPhone("(66) 9 9725-6560"),
      },
    ],
    icons: [
      {
        icon: "fab fa-facebook-square",
        link: "https://www.facebook.com/profile.php?id=100090863652232&rdid=HsBwiZmj4s87TJdn&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F19ovgEyws4%2F#",
        color: "#4267B2",
      },
      {
        src: require("../../assets/instagram.png"),
        link: "https://www.instagram.com/fasiperondonopolis",
        color: "#E1306C",
      },
      {
        icon: "fab fa-youtube",
        link: "https://www.youtube.com/channel/UCBUUgjiUn4fkhZHPbfo6I_g",
        color: "#FF0000",
      },
    ],
  },
  {
    title: "FACULDADE FASIPE SORRISO - MT",
    img: require("../../assets/units/faculties/fasipe-sorriso.png"),
    imgWidth: "250px",
    link: "https://fasipesorriso.com.br",
    address: [
      "Perimetral Sudoeste, nº 3675",
      "Bairro Flor do Cerrado, Sorriso - MT",
    ],
    tel: [
      {
        num: "(66) 9 9254-4850",
        link: getTelLinkFromPhone("(66) 9 9254-4850"),
      },
      {
        num: "(66) 9 9254-2386",
        link: getTelLinkFromPhone("(66) 9 9254-2386"),
      },
    ],
    icons: [
      {
        icon: "fab fa-facebook-square",
        link: "https://www.facebook.com/fasipesorriso/",
        color: "#4267B2",
      },
      {
        src: require("../../assets/instagram.png"),
        link: "https://www.instagram.com/fasipesorriso/",
        color: "#E1306C",
      },
    ],
  },
  {
    title: "FACULDADE FASIPE PRIMAVERA DO LESTE - MT",
    img: require("../../assets/units/faculties/fasipe-primavera.png"),
    imgWidth: "250px",
    link: "https://fasipeprimavera.com.br",
    address: [
      "Avenida Campo Grande, nº 1238",
      "Centro, Primavera do Leste - MT",
    ],
    tel: [
      {
        num: "(66) 9 9206-6647",
        link: getTelLinkFromPhone("(66) 9 9206-6647"),
      },
      {
        num: "(66) 9 9206-2418",
        link: getTelLinkFromPhone("(66) 9 9206-2418"),
      },
    ],
    icons: [
      {
        icon: "fab fa-facebook-square",
        link: "https://www.facebook.com/share/14JqBPSuVf/?mibextid=LQQJ4d",
        color: "#4267B2",
      },
      {
        src: require("../../assets/instagram.png"),
        link: "https://www.instagram.com/fasipeprimavera/",
        color: "#E1306C",
      },
      {
        icon: "fab fa-youtube",
        link: "https://www.youtube.com/@FasipePrimavera",
        color: "#FF0000",
      },
    ],
  },
  // {
  //   title: "DISTRITO FEDERAL - FASIPE DISTRITO FEDERAL",
  //   img: require("../../assets/units/faculties/fasipedf.png"),
  //   imgWidth: "250px",
  //   link: "https://fasipedf.com.br",
  //   local: "DISTRITO FEDERAL/DF",
  //   address: ["St. QI 20 ", "Taguatinga, Brasília - DF"],
  //   tel: [
  //     {
  //       num: "(61) 98545-3536",
  //       link: getTelLinkFromPhone("(61) 98545-3536"),
  //     },
  //   ],
  // },
  // {
  //   title: "BRASÍLIA - FASIPE DF",
  //   img: require("../../assets/units/faculties/fasipe-df.svg"),
  //   imgWidth: "250px",
  //   link: "https://fasipedf.com.br",
  //   local: "BRASÍLIA/DF",
  //   address: [
  //     "Avenida Campo Grande, nº 1238",
  //     "Centro - Primavera do Leste -MT",
  //   ],
  //   tel: [
  //     {
  //       num: "(66) 9 9206-6647",
  //       link: getTelLinkFromPhone("(66) 9 9206-6647"),
  //     },
  //     {
  //       num: "(66) 9 9206-2418",
  //       link: getTelLinkFromPhone("(66) 9 9206-2418"),
  //     },
  //   ],
  // },
  // {
  //   title: "CUIABÁ - FASIPE FERNANDO CORRÊA",
  //   img: require("../../assets/units/faculties/fasipe-fernando-correa.png"),
  //   imgWidth: "250px",
  //   local: "CUIABÁ/MT",
  //   address: ["Av. Fernando Corrêa da Costa, 3677", "Coxipó, Cuiabá - MT"],
  // },
];

const _schools = [
  {
    title: "SAN PETRUS SORRISO - MT",
    img: require("../../assets/units/schools/san-petrus.png"),
    imgWidth: "170px",
    link: "https://www.colegiosanpetrus.com.br/index.php?c=a",
    address: [
      "Rua São Silvestre, Nº 1636",
      "Jardim Flor do Cerrado, Sorriso - MT",
    ],
    tel: [
      {
        num: "(66) 9 9656-1297",
        link: getTelLinkFromPhone("(66) 9 9656-1297"),
      },
    ],
    icons: [
      {
        icon: "fab fa-facebook-square",
        link: "https://www.facebook.com/csp.sorriso",
        color: "#4267B2",
      },
      {
        src: require("../../assets/instagram.png"),
        link: "https://www.instagram.com/colegiosanpetrussorriso/",
        color: "#E1306C",
      },
      {
        icon: "fab fa-youtube",
        link: "https://www.youtube.com/@colegiosanpetrussorriso5030",
        color: "#FF0000",
      },
    ],
  },
  {
    title: "SAN PETRUS SINOP - MT",
    img: require("../../assets/units/schools/san-petrus.png"),
    imgWidth: "170px",
    link: "https://www.cspsinop.com.br",
    address: [
      "Avenida Magda de Cássia Pissinatti, nº 69",
      "Residencial Florença, Sinop - MT",
    ],
    tel: [
      {
        num: "(66) 9 9963-1919",
        link: getTelLinkFromPhone("(66) 9 9963-1919"),
      },
    ],
    icons: [
      {
        icon: "fab fa-facebook-square",
        link: "https://www.facebook.com/profile.php?id=100092987920120",
        color: "#4267B2",
      },
      {
        src: require("../../assets/instagram.png"),
        link: "https://www.instagram.com/colegiosanpetrussinop/",
        color: "#E1306C",
      },
      {
        icon: "fab fa-youtube",
        link: "https://www.youtube.com/@SanPetrusSinop/videos",
        color: "#FF0000",
      },
    ],
  },
  // {
  //   title: "CUIABÁ - COLÉGIO SAN PETRUS",
  //   img: require("../../assets/units/schools/san-petrus.png"),
  //   imgWidth: "170px",
  //   local: "CUIABÁ/MT",
  // },
];

import Brands from "../cards/Brands.vue";

export default {
  data: () => ({
    goTo: "",
    faculties: _faculties,
    schools: _schools,
  }),
  computed: {
    lg() {
      return this.$vuetify.breakpoint.lgOnly;
    },
    xl() {
      return this.$vuetify.breakpoint.xlOnly;
    },
    items() {
      return [
        ...[{ header: "Faculdades" }],
        ...this.faculties.map(({ title, link }, index) => ({
          value: link ? link : index,
          text: title,
          disabled: !link ? true : false,
        })),
        ...[{ header: "Colégios" }],
        ...this.schools.map(({ title, link }, index) => ({
          value: link ? link : index,
          text: title,
          disabled: !link ? true : false,
        })),
      ];
    },
  },
  watch: {
    goTo() {
      location = this.goTo;
    },
  },
  components: {
    Brands,
  },
};
</script>

<style lang="scss" scoped>
.grid-list-faculties,
.grid-list-schools {
  display: grid;
  justify-content: center;
  gap: 60px;
}

.grid-list-faculties {
  grid-template-columns: repeat(3, minmax(0, 330px));

  @media screen and (max-width: 959.9px) {
    grid-template-columns: repeat(2, minmax(0, 330px));
  }

  @media screen and (max-width: 599.9px) {
    grid-template-columns: 330px;
  }
}

.grid-list-schools {
  grid-template-columns: repeat(2, minmax(0, 300px));

  @media screen and (max-width: 599.9px) {
    grid-template-columns: 300px;
  }
}

@media (max-width: 960px) {
  .header-brands .brand-group {
    height: 112px;
  }

  .header-brands .separator {
    height: 51px;
  }

  .header-brands .age {
    height: 152px;
  }
}

@media (max-width: 600px) {
  .header-brands .brand-group {
    height: 80px;
  }

  .header-brands .separator {
    height: 36.5px;
  }

  .header-brands .age {
    height: 108.5px;
  }
}

.text {
  letter-spacing: 10px !important;
}

@media screen and (max-width: 600px) {
  .text {
    letter-spacing: 5px !important;
  }
}
</style>
